import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; 2020 The Location Company | <a href="mailto:info@thelocation.co">Contact</a></p>
        </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
